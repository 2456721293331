
















import { Component, Mixins } from 'vue-property-decorator'
import { Bind, Debounce } from 'lodash-decorators'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import ManagerDepositoryModule from '@/store/modules/manager/depository'
// components
import MaterialForm from '@/components/forms/depository/MaterialForm.vue'
// store
import AuthModule from '@/store/modules/auth'
import { IUsefulMaterialForm } from '@/store/types/forms'
import { UsefulMaterialPatchBody } from '@/store/types'
// utils
import { transformRequestMaterialForm, transformResponseMaterialForm } from '@/utils/functions'

@Component({
  components: {
    MaterialForm,
  },
})
export default class DepositoryCourseItemMaterialsEdit extends Mixins(NotifyMixin) {

  private form: IUsefulMaterialForm | null = null

  private courseID = this.$route.params.courseID
  private materialID = this.$route.params.materialID

  private get breadcrumbs () {
    return [
      { name: 'База знаний', path: { name: 'manager.bank.depository' } },
      { name: this.course?.name, path: { name: 'manager.bank.depository.courses.item.materials', params: { courseID: this.course?.value } } },
    ]
  }

  private get course () {
    return ManagerDepositoryModule.course
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private mounted() {
    if (this.course && this.course.value !== +this.courseID || !this.course) {
      ManagerDepositoryModule.fetchCourse(+this.courseID)
        .catch((error) => {
          this.notifyError(error)
          this.$router.push({ name: 'manager.bank.depository.courses' })
        })
    }
    if (!ManagerDepositoryModule.currentMaterial || ManagerDepositoryModule.currentMaterial.id !== +this.materialID) {
      this.fetchMaterial()
    } else {
      this.form = transformResponseMaterialForm(+this.courseID, ManagerDepositoryModule.currentMaterial, this.isLocalTimezone)
    }
  }

  private updateMaterial(form: IUsefulMaterialForm) {
    ManagerDepositoryModule.updateMaterial({ body: transformRequestMaterialForm(form, this.isLocalTimezone, +this.courseID) as UsefulMaterialPatchBody, materialId: +this.materialID })
      .then(() => {
        this.notifySuccess('Материал успешно обновлён.')
      })
      .catch(this.notifyError)
  }

  private removeMaterial() {
    ManagerDepositoryModule.removeMaterial({ courseId: +this.courseID, materialId: +this.materialID })
      .then(() => {
        this.notifySuccess('Материал удален успешно.')
        this.$router.push({ name: 'manager.bank.depository.courses.item', params: { courseID: this.courseID } })
      })
      .catch(this.notifyError)
  }

  @Bind
  @Debounce(300)
  private fetchMaterial() {
    ManagerDepositoryModule.fetchMaterial(+this.materialID)
      .then(response => {
        this.form = transformResponseMaterialForm(+this.courseID, response, this.isLocalTimezone)
      })
      .catch(this.notifyError)
  }

}
