
































import { Bind, Debounce } from 'lodash-decorators'
import { uniqBy } from 'lodash'
import { Component, Mixins, Watch, Prop } from 'vue-property-decorator'

import DetectSafariMixin from '@/mixins/DetectSafariMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import ManagerDepositoryModule from '@/store/modules/manager/depository'
import { NameValueResource } from '@/store/types'

@Component({
  inheritAttrs: false,
})
export default class CouseMultipleSearchInUsefulMaterials extends Mixins(DetectSafariMixin, NotifyMixin) {
  @Prop({ default: () => ([]) })
  private value!: number[]

  @Prop({ default: () => ([]) })
  private options!: NameValueResource[]

  @Prop({ default: 'Поиск пользователей' })
  private label!: string

  @Prop({ default: 'Начните вводить для поиска' })
  private placeholder!: string

  // Переносить ли выбранные пункты в селекте на несколько строк
  @Prop({ default: false })
  private isSelectedWrap!: boolean

  private get courseID() {
    return +this.$route.params.courseID
  }

  private search = ''
  private isLoading = false
  private coursesList: NameValueResource[] = []
  private coursesSelected: number[] = []

  private mounted () {
    this.coursesList = this.options
    this.coursesSelected = [...this.value]
  }

  private reset () {
    this.search = ''
    this.coursesList = []
    this.coursesSelected = []
  }

  private handleInput () {
    this.search = ''
    this.$emit('update:value', this.coursesSelected)
  }

  private handleDelete (item: number) {
    const index = this.coursesSelected.indexOf(item)
    if (index >= 0) {
      this.coursesSelected.splice(index, 1)
      this.$emit('update:value', this.coursesSelected)
    }
  }

  @Bind
  @Debounce(500)
  private handleSearch (query: string) {
    if (this.isLoading || !query || !query.trim()) return

    this.isLoading = true
    ManagerDepositoryModule.fetchMaterialCourses({
      query,
      excludeCourseIds: [...this.coursesSelected, this.courseID],
    })
      .then(response => {
        this.coursesList = uniqBy([...this.coursesList, ...response], 'value')
      })
      .catch(this.notifyError)
      .finally(() => { this.isLoading = false })
  }

  @Watch('search')
  private watchSearch (value: string) {
    if (value) {
      this.handleSearch(value)
    }
  }
}
