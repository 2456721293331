














































































import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import NotifyMixin from '@/mixins/NotifyMixin'
// components
import AttachmentsWrapper from '@/components/_uikit/AttachmentsWrapper.vue'
import ButtonTextIcon from '@/components/_uikit/buttons/ButtonTextIcon.vue'
import DateTimeInput from '@/components/_uikit/controls/DateTimeInput.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import SelectFilesButton from '@/components/_uikit/buttons/SelectFilesButton.vue'
import TiptapEditor from '@/components/_uikit/editor/TiptapEditor.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import CouseMultipleSearchInUsefulMaterials from '@/components/_uikit/search/CouseMultipleSearchInUsefulMaterials.vue'
// store
import DictionaryModule from '@/store/modules/dictionary'
import ManagerDepositoryModule from '@/store/modules/manager/depository'
// types
import { MediaResource, NameValueResource } from '@/store/types'
import { IUsefulMaterialForm } from '@/store/types/forms'

@Component({
  components: {
    AttachmentsWrapper,
    ButtonTextIcon,
    DateTimeInput,
    Select,
    SelectFilesButton,
    TextInput,
    TiptapEditor,
    ValidationObserver,
    ValidationProvider,
    CouseMultipleSearchInUsefulMaterials,
  },
})
export default class MaterialForm extends Mixins(NotifyMixin) {
  @Prop({ required: true })
  private form!: IUsefulMaterialForm

  @Prop({ default: true })
  private edit!: boolean

  private readonly = false
  private searchType = ''

  private get courseID() {
    return +this.$route.params.courseID
  }

  private get mediaFiles(): MediaResource[] {
    if (this.edit && ManagerDepositoryModule.currentMaterial)
      return ManagerDepositoryModule.currentMaterial.media
    return []
  }

  private get materialTypes(): NameValueResource[] {
    return DictionaryModule.usefulMaterialsTypes
  }

  private get courses(): NameValueResource[] {
    return ManagerDepositoryModule.materialCourses
  }

  private mounted() {
    if (!ManagerDepositoryModule.materialCourses.length) {
      this.fetchCourses()
    } else {
      this.coursesIsLoaded = true
    }
  }

  private handleSubmit() {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        result ? this.$emit('submit', this.form) : this.notifyError('Проверьте введенные данные')
      })
  }

  private handleRemove() {
    this.$emit('remove')
  }

  private coursesIsLoaded = false

  @Bind
  @Debounce(300)
  private fetchCourses () {
    ManagerDepositoryModule.fetchMaterialCourses({ excludeCourseIds: [this.courseID] })
      .then(() => this.coursesIsLoaded = true)
      .catch(this.notifyError)
  }
}
